import { cn } from '@/app/utils/cn';
import Image from 'next/image';
import { useState } from 'react';

export interface InformationHoverProps extends React.ComponentPropsWithoutRef<'div'> {
    iconName?: string;
    text: string;
    classNames?: { parent?: string; messageContent?: string; message?: string };
}

const InformationHover = ({
    iconName = 'question-circle-dark',
    text,
    classNames
}: InformationHoverProps) => {
    const [helperHovered, setHelperHovered] = useState<boolean>(false);

    return (
        <div className={cn('relative flex items-center justify-center', classNames?.parent)}>
            {helperHovered && (
                <div
                    className={cn(
                        'absolute transform -translate-x-[45%] -translate-y-[70%] z-50 bg-gray-F2 shadow-lg rounded-[7px]',
                        'w-[80vw] max-w-[590px] px-[10px] py-[4px]',
                        'md:-translate-x-1 md:px-[22px]',
                        classNames?.messageContent
                    )}
                >
                    <span
                        className={cn(
                            'text-[10px] text-gray-70 text-center block',
                            classNames?.message
                        )}
                    >
                        {text}
                    </span>
                </div>
            )}

            <Image
                height={24}
                width={24}
                src={`/icons/${iconName}.svg`}
                alt={iconName}
                onMouseOver={() => setHelperHovered(true)}
                onMouseOut={() => setHelperHovered(false)}
            />
        </div>
    );
};

export default InformationHover;
